import axios from 'axios';

export class UserService {

    async getAll() {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/users`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
                return error.response;
            });
    }

    async create(data) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/user`, data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
                return error.response;
            });
    }

    async update(data) {
        return await axios
            .put(`${process.env.REACT_APP_API_URL}/api/user/${data.id}`, data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
                return error.response;
            });
    }

    async login(data) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/login`, data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
                return error.response;
            });
    }
}
