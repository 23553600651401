import axios from 'axios';

export class ClientSupplieService {

    async create(data) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/client_supplie`, data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
                return error.response;
            });
    }

    async updateEntrega(data) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/client_supplie/endDate/${data.id}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
                return error.response;
            });
    }

    async update(data) {
        return await axios
            .put(`${process.env.REACT_APP_API_URL}/api/client_supplie/${data.id}`, data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
                return error.response;
            });
    }

    async delete(data) {
        return await axios
            .delete(`${process.env.REACT_APP_API_URL}/api/client_supplie/${data.id}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
                return error.response;
            });
    }
}
