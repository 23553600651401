import React, { useContext, useRef, useState } from "react";
import { UsuarioContext } from "../hooks/UserContext";
import { Toast } from "primereact/toast";
import { DialogProcesando } from "../components/DialogProcesando";
import { Redirect } from "react-router-dom";
import { UserService } from "../service/UserService";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

let loginVacio = {
    email: "",
    password: "",
};

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100vh",
    },
    image: {
        backgroundImage: "url(https://images.unsplash.com/photo-1609606827702-edf12c39c486?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max)",
        backgroundRepeat: "no-repeat",
        backgroundColor: theme.palette.type === "light" ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: "cover",
        backgroundPosition: "center",
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const userService = new UserService();

export const Login = ({ seccion, setSeccion }) => {
    const { usuario, setUsuario } = useContext(UsuarioContext);
    const toast = useRef(null);
    const [login, setLogin] = useState(loginVacio);
    const [loadingCargando, setLoadingCargando] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const classes = useStyles();

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _login = { ...login };
        _login[`${name}`] = val;

        setLogin(_login);
    };

    const logear = () => {
        setLoadingCargando(true);
        setSubmitted(true);
        if (!login.email.length || !login.password.length) {
            setLoadingCargando(false);
            return;
        }

        setSubmitted(false);

        userService.login(login).then(({ data, status, msg }) => {
            if (status === 'success') {
                setUsuario(data);
            } else {
                toast.current.show({ severity: "error", summary: "Error", detail: msg, life: 3000 });
            }
            setLoadingCargando(false);
        });
    };

    if (!seccion) {
        setSeccion(true);
        toast.current.show({ severity: "error", summary: "Seción Expirada", detail: "Porfavor vuelva a ingresar", life: 6000 });
    }

    if (!usuario) {
        return (
            <>
                <Grid container component="main" className={classes.root}>
                    <DialogProcesando visible={loadingCargando} />
                    <Toast ref={toast} />

                    <CssBaseline />
                    <Grid item xs={false} sm={4} md={7} className={classes.image} />
                    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                        <div className={classes.paper}>
                            <Avatar className={classes.avatar}>
                                <LockOutlinedIcon />
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                Ingreso
                            </Typography>
                            <form className={classes.form} noValidate>
                                <TextField variant="outlined" margin="normal" onChange={(e) => onInputChange(e, "email")} required fullWidth name="email" label="Email" type="email" id="email" autoFocus helperText={submitted && !login.email && <small className="p-error">El Email es requerido.</small>} />
                                <TextField variant="outlined" margin="normal" onChange={(e) => onInputChange(e, "password")} required fullWidth label="Contraseña" type="password" id="contraseña" helperText={submitted && !login.password && <small className="p-error">La contraseña es requerida.</small>} name="contraseña" />

                                <Button onClick={logear} fullWidth variant="contained" color="primary" className={classes.submit}>
                                    Ingresar
                                </Button>

                                <Box mt={5}></Box>
                            </form>
                        </div>
                    </Grid>
                </Grid>
            </>
        );
    } else {
        return <Redirect to={"/"} />;
    }
};
