import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable'
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { ClientSupplieService } from '../service/ClientSupplieService';
import { DialogProcesando } from './DialogProcesando';
import { Dialog } from 'primereact/dialog';

export const SuppliesFaltantes = ({ client, supplies, clients, setClients, setClient }) => {
    const [globalFilter, setGlobalFilter] = useState(null);
    const [suppliesFaltantes, setSuppliesFaltantes] = useState([]);
    const [observationDialog, setObservationDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [observation, setObservation] = useState('');
    const [supplie, setSupplie] = useState(null);

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < clients.length; i++) {
            if (clients[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const toast = useRef(null);
    useEffect(() => {
        let _suppliesFaltantes = [...supplies];
        client.client_supplies.forEach((value) => {
            _suppliesFaltantes = _suppliesFaltantes.filter((element) => element.id !== value.supplies_id);
        })
        setSuppliesFaltantes(_suppliesFaltantes);
    }, [client.client_supplies, supplies])

    const addSupplieToClient = () => {
        const data = {
            clients_id: client.id,
            supplies_id: supplie.id,
            observation,
        }
        const clientSupplieService = new ClientSupplieService();
        setLoading(true);
        clientSupplieService.create(data).then(({ status, data }) => {
            if (status === 'success') {
                toast.current.show({ severity: 'success', summary: 'Éxito', detail: 'Medicamento agregado exitosamente', life: 3000 });
                let _suppliesFaltantes = suppliesFaltantes.filter((value) => value.id !== supplie.id);
                setSuppliesFaltantes(_suppliesFaltantes);
                let _client = { ...client };
                let _clients = [...clients];
                const index = findIndexById(client.id);
                _client.client_supplies.push({
                    observation: observation,
                    supplie: { name: supplie.name },
                    clients_id: client.id,
                    id: data.id,
                });
                _clients[index] = _client;
                setClient({ ..._client })
                setClients([..._clients]);
                setObservation('');
                setObservationDialog(false);
            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'El Medicamento no se ha podido agregar', life: 3000 });
            }
            setLoading(false);
        })
    }

    const showObservationDialog = (rowData) => {
        setObservation('');
        setSupplie(rowData);
        setObservationDialog(true);
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-plus" className="p-button-rounded p-button-success p-mr-2" onClick={() => showObservationDialog(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Listado Medicamentos</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const hideDialog = () => {
        setObservationDialog(false);
    }

    const clientDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={addSupplieToClient} />
        </>
    );

    return (
        <>
            <Toast ref={toast} />
            <DialogProcesando visible={loading} />
            <DataTable value={suppliesFaltantes}
                dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} medicamentos"
                globalFilter={globalFilter} emptyMessage="Sin medicamentos para seleccionar." header={header}>
                <Column field="name" header="Nombre" sortable></Column>
                <Column body={actionBodyTemplate} header="Agregar Medicamento"></Column>
            </DataTable>
            <Dialog visible={observationDialog} style={{ width: '450px' }} header="Observación" modal className="p-fluid" footer={clientDialogFooter} onHide={hideDialog}>
                <div className="p-field">
                    <label htmlFor="name">Observación</label>
                    <InputText id="name" value={observation} onChange={(e) => setObservation(e.target.value)} required autoFocus />
                </div>
            </Dialog>
        </>
    )
}
