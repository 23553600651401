import React, { useContext } from "react";
import { Redirect } from 'react-router-dom';
import { UsuarioContext } from "./hooks/UserContext";

export const Auth = ({ Component }) => {
    const { usuario } = useContext(UsuarioContext);
    if (!usuario) {
        return <Redirect to='/login' />
    }
    return <Component />;
};
