import { Dialog } from "primereact/dialog";
import { ProgressBar } from "primereact/progressbar";
import React from "react";

const ocultarCarga = () => {
    return;
};

export const DialogProcesando = ({ visible }) => {
    return (
        <Dialog visible={visible} onHide={ocultarCarga}>
            <div className="card">
                <h1>Procesando Información...</h1>
                <ProgressBar mode="indeterminate" style={{ height: "15px" }}></ProgressBar>
                <br />
                <p>Espere un momento mientras se validan los datos ingresados al sistema</p>
            </div>
        </Dialog>
    );
};
