import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { ClientService } from '../service/ClientService';
import { SupplieService } from '../service/SupplieService';
import { ClientSupplieService } from '../service/ClientSupplieService';
import { DialogProcesando } from '../components/DialogProcesando';
import Barcode from 'react-barcode';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { SuppliesFaltantes } from '../components/SuppliesFaltantes';

const clientService = new ClientService();
const clientSupplieService = new ClientSupplieService();

export const Clients = () => {

    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Claro'
    });

    let emptyClient = {
        id: null,
        name: '',
        lastname: '',
        mother_lastname: '',
        rut: '',
        street: '',
        street_number: 0,
        phone_number: '',
        rsh: '',
        birthdate: null,
        attention: 'NINGUNO',
        diagnosis: '',
        client_supplies: [],
    };

    const exportPdf = async () => {
        html2canvas(document.querySelector("#capture")).then(canvas => {
            document.body.appendChild(canvas);  // if you want see your screenshot in body.
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            pdf.addImage(imgData, 'PNG', 0, 0);
            pdf.save("download.pdf");
        });
    }

    const [clients, setClients] = useState([]);
    const [clientDialog, setClientDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [clientDetailDialog, setClientDetailDialog] = useState(false);
    const [supplies, setSupplies] = useState([]);
    const [loading, setLoading] = useState(true);
    const [client, setClient] = useState(emptyClient);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [supplieFilter, setSupplieFilter] = useState(null);
    const [suppliesFaltantesDialog, setSuppliesFaltantesDialog] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [editDialog, setEditDialog] = useState(false);
    const [supplie, setSupplie] = useState(null);
    const [rutSeteado, setRutSeteado] = useState('');
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        if (globalFilter?.length === 13) {
            let cuerpo = globalFilter.slice(0, -1);
            const dv = cuerpo[11];
            cuerpo = globalFilter.slice(0, dv);
            clients.forEach((value) => {
                let valorRut = value.rut.replace('.', '');
                valorRut = valorRut.replace('-', '');
                let cuerpoRut = valorRut.slice(0, -1);
                if (cuerpo === cuerpoRut) {
                    setClient(value);
                    setClientDetailDialog(true);
                }
            })
        }
    }, [globalFilter])

    useEffect(() => {
        if (client) {
            let valor = client.rut.replace('.', '');
            valor = valor.replace('-', '');
            let cuerpo = valor.slice(0, -1);
            const seteado = cuerpo.length === 8 ? `${cuerpo}0008` : `${cuerpo}00007`;
            setRutSeteado(seteado);
        }
    }, [client])

    useEffect(() => {
        clientService.getAll().then(data => {
            setClients(data.data);
            setLoading(false);
        });
        const supplieService = new SupplieService();
        supplieService.getAll().then(data => {
            setSupplies(data.data);
        })
    }, []);

    const onAttentionChange = (e) => {
        let _client = { ...client };
        _client['attention'] = e.value;
        setClient(_client);
    }

    const openNew = () => {
        setClient(emptyClient);
        setSubmitted(false);
        setClientDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setClientDialog(false);
    }

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    }

    const saveProduct = () => {
        setSubmitted(true);

        let _client = { ...client };
        if (client.name.trim() && client.rut.trim()) {
            setLoading(true);
            let _clients = [...clients];
            if (client.id) {

                const index = findIndexById(client.id);
                if (client.birthdate) {
                    _client.birthdate = new Date(_client.birthdate);
                }
                clientService.update(_client).then(({ status, data }) => {
                    if (status === 'success') {
                        _clients[index] = _client;
                        setClients([..._clients]);
                        setLoading(false);
                        toast.current.show({ severity: 'success', summary: 'Éxito', detail: 'Cliente actualizado exitosamente', life: 3000 });
                    }
                });
            }
            else {
                _client.birthdate = new Date(_client.birthdate);
                clientService.create(_client).then(({ status, data }) => {
                    if (status === 'success') {
                        setClients([...clients, { ...data, client_supplies: [] }]);
                        setLoading(false);
                        toast.current.show({ severity: 'success', summary: 'Éxito', detail: 'Cliente creado exitosamente', life: 3000 });
                    }
                });
            }
            setClientDialog(false);
            setClient(emptyClient);
        }
    }

    const editProduct = (product) => {
        setClient({ ...product });
        setClientDialog(true);
    }

    const deleteProduct = () => {
        let _clients = clients.filter(val => val.id !== client.id);
        setClients(_clients);
        setDeleteProductDialog(false);
        setClient(emptyClient);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Deleted', life: 3000 });
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < clients.length; i++) {
            if (clients[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _client = { ...client };
        _client[`${name}`] = val;

        setClient(_client);
    }

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _client = { ...client };
        _client[`${name}`] = val;

        setClient(_client);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nuevo" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} />
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Exportar" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const clientDetailDialogShow = (rowData) => {
        setClient(rowData);
        setClientDetailDialog(true);
    }

    const clientDetailDialogHide = () => {
        setClient(emptyClient);
        setClientDetailDialog(false);
        setSupplieFilter(null);
    }

    const hideEditDialog = () => {
        setEditDialog(false);
        setSupplie(null);
    }

    const hideDeleteDialog = () => {
        setDeleteDialog(false);
        setSupplie(null);
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => editProduct(rowData)} />
                <Button icon="pi pi-info-circle" className="p-button-rounded p-button-info" onClick={() => clientDetailDialogShow(rowData)} />
            </div>
        );
    }

    const editSupplieClient = (rowData) => {
        setSupplie(rowData);
        setEditDialog(true);
    }

    const deleteSupplieClient = (rowData) => {
        setSupplie(rowData);
        setDeleteDialog(true);
    }

    const updateEntrega = (rowData) => {
        setLoading(true);
        clientSupplieService.updateEntrega(rowData).then(({ status, data }) => {
            if (status === 'success') {
                const _clientSupplies = client.client_supplies.map((val) => {
                    let res = {};
                    if (val.id === rowData.id) {
                        res = {
                            ...val,
                            end_date: data.end_date
                        }
                    } else {
                        res = { ...val }
                    }
                    return res
                })
                setClient({ ...client, client_supplies: [..._clientSupplies] })
                setLoading(false);
                toast.current.show({ severity: 'success', summary: 'Éxito', detail: 'Entrega actualizada exitosamente', life: 3000 });
            }
        });
    }

    const supplieClientAction = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-calendar-plus" tooltip="Actualizar ultima fecha entrega" className="p-button-rounded p-button-info p-mr-2" onClick={() => updateEntrega(rowData)} />
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => editSupplieClient(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-mr-2" onClick={() => deleteSupplieClient(rowData)} />
            </div>
        );
    }


    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Listado Clientes</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const headerClientSupplies = (
        <div className="table-header">
            <h5 className="p-m-0">Listado Medicamentos</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setSupplieFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div
        >
    );

    const requestEditDialog = () => {
        setLoading(true);
        clientSupplieService.update(supplie).then(({ status, data }) => {
            if (status === 'success') {
                setEditDialog(false);
                setLoading(false);
                toast.current.show({ severity: 'success', summary: 'Éxito', detail: 'Observación actualizada correctamente', life: 3000 });
                let _client = clients.find(val => val.id === supplie.clients_id);
                let _supplie = _client.client_supplies.find(val => val.id === supplie.id);
                _supplie = {
                    ..._supplie,
                    observation: supplie.observation,
                }
                const client_supplies = _client.client_supplies.map(val => {
                    if (val.id === supplie.id) {
                        return _supplie
                    } else {
                        return val
                    }
                })

                _client = { ..._client, client_supplies: [...client_supplies] }

                const dataEditSupplieClient = clients.map(val => {
                    if (val.id === supplie.clients_id) {
                        return _client
                    } else {
                        return val;
                    }
                })

                setClient(_client);
                setClients(dataEditSupplieClient);

            }
        })
    }

    const requestDeleteDialog = () => {
        setLoading(true);
        clientSupplieService.delete(supplie).then(({ status, data }) => {
            if (status === 'success') {
                setDeleteDialog(false);
                setLoading(false);
                toast.current.show({ severity: 'success', summary: 'Éxito', detail: 'Farmaco eliminado correctamente', life: 3000 });
                let _client = clients.find(val => val.id === supplie.clients_id);
                const client_supplies = _client.client_supplies.filter(val => val.id !== supplie.id)

                _client = { ..._client, client_supplies: [...client_supplies] }

                const dataEditSupplieClient = clients.map(val => {
                    if (val.id === supplie.clients_id) {
                        return _client
                    } else {
                        return val;
                    }
                })

                setClient(_client);
                setClients(dataEditSupplieClient);

            }
        })
    }

    const editDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideEditDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={requestEditDialog} />
        </>
    );

    const deleteDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={requestDeleteDialog} />
        </>
    );

    const clientDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );

    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </>
    );

    const imprimirInfo = () => {
        window.open(`${process.env.REACT_APP_API_URL}/api/client/document/${client.id}`)
    }


    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable ref={dt} value={clients} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} clientes" loading={loading}
                        globalFilter={globalFilter} emptyMessage="Sin clientes registrados." header={header}>
                        <Column field="name" header="Nombre" sortable></Column>
                        <Column field="lastname" header="Apellido Paterno" sortable></Column>
                        <Column field="mother_lastname" header="Apellido Materno" sortable></Column>
                        <Column field="rut" header="RUT" sortable></Column>
                        <Column field="street" header="Calle" sortable></Column>
                        <Column field="street_number" header="Numero" sortable></Column>
                        <Column field="phone_number" header="Numero Telefonico" sortable></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <DialogProcesando visible={loading} />

                    <Dialog visible={clientDetailDialog} style={{ width: '80%' }} header={`Cliente ${client.name}`} modal className="p-fluid" onHide={clientDetailDialogHide} >
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button icon="pi pi-print" tooltip="Imprimir" className="p-button-rounded p-button-help p-mr-2" onClick={imprimirInfo} />
                        </div>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col-6">
                                <label htmlFor="name">Nombre</label>
                                <InputText id="name" value={client.name} disabled />
                            </div>
                            <div className="p-field p-col-6">
                                <label htmlFor="lastname">Apellido Paterno</label>
                                <InputText id="lastname" value={client.lastname} disabled />
                            </div>
                        </div>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col-6">
                                <label htmlFor="mother_lastname">Apellido Materno</label>
                                <InputText id="mother_lastname" value={client.mother_lastname} disabled />
                            </div>

                            <div className="p-field p-col-6">
                                <label htmlFor="rut">R.U.T</label>
                                <InputText id="rut" value={client.rut} disabled />
                            </div>
                        </div>

                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col-6">
                                <label htmlFor="street">Calle</label>
                                <InputText id="street" value={client.street} disabled />
                            </div>
                            <div className="p-field p-col-6">
                                <label htmlFor="street_number">Numero</label>
                                <InputNumber id="street_number" value={client.street_number} disabled />
                            </div>
                        </div>

                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col-6">
                                <label htmlFor="phone_number">Numero Telefonico</label>
                                <InputText id="phone_number" value={client.phone_number} disabled />
                            </div>
                            <div className="p-field p-col-6">
                                <label htmlFor="rsh">Registro Social de Hogares</label>
                                <InputText id="rsh" value={client.rsh} disabled />
                            </div>
                        </div>

                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col-6">
                                <label htmlFor="birthdate">Fecha de Nacimiento</label>
                                <InputText id="birthdate" value={new Date(client.birthdate).toDateString()} disabled />
                            </div>
                            <div className="p-field p-col-6">
                                <label htmlFor="attention">Atencion de Salud</label>
                                <InputText id="attention" value={client.attention} disabled />
                            </div>
                        </div>

                        <div className="p-field">
                            <label htmlFor="diagnosis">Diagnostico</label>
                            <InputTextarea id="diagnosis" value={client.diagnosis} disabled rows={3} cols={20} />
                        </div>
                        <div className="p-formgrid p-grid">
                            <label htmlFor="phone_number">Codigo de Barra</label>
                        </div>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col-6">
                                <div id="capture">
                                    <Barcode value={rutSeteado} format={'EAN13'} />
                                </div>
                            </div>
                            <div className="p-field p-col-6">
                                <Button onClick={exportPdf} label="Exportar Codigo De Barra" />
                            </div>
                        </div>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col-6">
                                <Button onClick={() => setSuppliesFaltantesDialog(true)} label="Agregar Medicamentos al Cliente" />
                            </div>
                        </div>

                        <DataTable ref={dt} value={client.client_supplies}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} medicamentos"
                            globalFilter={supplieFilter} emptyMessage="Sin medicamentos registrados." header={headerClientSupplies}>
                            <Column field="supplie.name" header="Nombre" sortable></Column>
                            <Column field="observation" header="Observación" sortable></Column>
                            <Column field="end_date" header="Ultima entrega" sortable></Column>
                            <Column body={supplieClientAction}></Column>
                        </DataTable>
                    </Dialog>

                    <Dialog visible={suppliesFaltantesDialog} style={{ width: '80%' }} header={`Agregar nuevos medicamentos al Cliente: ${client.name} ${client.lastname}`} modal className="p-fluid" onHide={setSuppliesFaltantesDialog}>
                        <SuppliesFaltantes client={client} supplies={supplies} clients={clients} setClients={setClients} setClient={setClient} />
                    </Dialog>

                    <Dialog visible={editDialog} style={{ width: '450px' }} header="Modificar Observació" modal className="p-fluid" footer={editDialogFooter} onHide={hideEditDialog}>
                        <div className="p-field">
                            <label htmlFor="name">Observación</label>
                            <InputText id="name" value={supplie?.observation} onChange={(e) => setSupplie({ ...supplie, observation: e.target.value })} required autoFocus />
                        </div>
                    </Dialog>

                    <Dialog visible={clientDialog} style={{ width: '450px' }} header="Cliente Detalles" modal className="p-fluid" footer={clientDialogFooter} onHide={hideDialog}>
                        <div className="p-field">
                            <label htmlFor="name">Nombre</label>
                            <InputText id="name" value={client.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !client.name })} />
                            {submitted && !client.name && <small className="p-invalid" style={{ color: 'red' }}>El nombre es requerdio.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="lastname">Apellido Paterno</label>
                            <InputText id="lastname" value={client.lastname} onChange={(e) => onInputChange(e, 'lastname')} required autoFocus />
                        </div>
                        <div className="p-field">
                            <label htmlFor="mother_lastname">Apellido Materno</label>
                            <InputText id="mother_lastname" value={client.mother_lastname} onChange={(e) => onInputChange(e, 'mother_lastname')} required autoFocus />
                        </div>

                        <div className="p-field">
                            <label htmlFor="rut">R.U.T</label>
                            <InputText id="rut" value={client.rut} onChange={(e) => onInputChange(e, 'rut')} required autoFocus className={classNames({ 'p-invalid': submitted && !client.rut })} />
                            {submitted && !client.rut && <small className="p-invalid" style={{ color: 'red' }}>El Rut es requerdio.</small>}
                        </div>

                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="street">Calle</label>
                                <InputText id="street" value={client.street} onChange={(e) => onInputChange(e, 'street')} required autoFocus />
                            </div>
                            <div className="p-field p-col">
                                <label htmlFor="street_number">Numero</label>
                                <InputNumber id="street_number" value={client.street_number} onValueChange={(e) => onInputNumberChange(e, 'street_number')} integeronly />
                            </div>
                        </div>

                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="phone_number">Numero Telefonico</label>
                                <InputText id="phone_number" value={client.phone_number} onChange={(e) => onInputChange(e, 'phone_number')} required autoFocus />
                            </div>
                            <div className="p-field p-col-6">
                                <label htmlFor="rsh">Registro Social de Hogares</label>
                                <InputText id="rsh" value={client.rsh} onChange={(e) => onInputChange(e, 'rsh')} required autoFocus />
                            </div>
                        </div>

                        <div className="p-fiel">
                            <label htmlFor="navigators">Fecha de Nacimiento</label>
                            <Calendar id="navigators" inline value={client.birthdate} onChange={(e) => setClient({ ...client, birthdate: e.value })} monthNavigator yearNavigator yearRange="1900:2021" locale="es" />
                        </div>

                        <br />

                        <div className="p-field">
                            <label className="p-mb-3">Atencion de Salud</label>
                            <div className="p-formgrid p-grid">
                                <div className="p-field-radiobutton p-col-6">
                                    <RadioButton inputId="category1" name="category" value="HOSPITAL YUMBEL" onChange={onAttentionChange} checked={client.attention === 'HOSPITAL YUMBEL'} />
                                    <label htmlFor="category1">HOSPITAL YUMBEL</label>
                                </div>
                                <div className="p-field-radiobutton p-col-6">
                                    <RadioButton inputId="category2" name="category" value="CESFAM FVM" onChange={onAttentionChange} checked={client.attention === 'CESFAM FVM'} />
                                    <label htmlFor="category2">CESFAM FVM</label>
                                </div>
                                <div className="p-field-radiobutton p-col-6">
                                    <RadioButton inputId="category3" name="category" value="POSTA RERE" onChange={onAttentionChange} checked={client.attention === 'POSTA RERE'} />
                                    <label htmlFor="category3">POSTA RERE</label>
                                </div>
                                <div className="p-field-radiobutton p-col-6">
                                    <RadioButton inputId="category4" name="category" value="POSTA RIO CLARO" onChange={onAttentionChange} checked={client.attention === 'POSTA RIO CLARO'} />
                                    <label htmlFor="category4">POSTA RIO CLARO</label>
                                </div>
                                <div className="p-field-radiobutton p-col-6">
                                    <RadioButton inputId="category5" name="category" value="POSTA CANCHILLA" onChange={onAttentionChange} checked={client.attention === 'POSTA CANCHILLA'} />
                                    <label htmlFor="category5">POSTA CANCHILLA</label>
                                </div>
                                <div className="p-field-radiobutton p-col-6">
                                    <RadioButton inputId="category6" name="category" value="POSTA LA AGUADA" onChange={onAttentionChange} checked={client.attention === 'POSTA LA AGUADA'} />
                                    <label htmlFor="category6">POSTA LA AGUADA</label>
                                </div>
                                <div className="p-field-radiobutton p-col-6">
                                    <RadioButton inputId="category7" name="category" value="POSTA TOMECO" onChange={onAttentionChange} checked={client.attention === 'POSTA TOMECO'} />
                                    <label htmlFor="category7">POSTA TOMECO</label>
                                </div>
                                <div className="p-field-radiobutton p-col-6">
                                    <RadioButton inputId="category8" name="category" value="NINGUNO" onChange={onAttentionChange} checked={client.attention === 'NINGUNO'} />
                                    <label htmlFor="category8">NINGUNO</label>
                                </div>
                            </div>
                        </div>

                        <div className="p-field">
                            <label htmlFor="diagnosis">Diagnostico</label>
                            <InputTextarea id="diagnosis" value={client.diagnosis} onChange={(e) => onInputChange(e, 'diagnosis')} required rows={3} cols={20} />
                        </div>
                    </Dialog>

                    <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirmar" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {client && <span>Seguro que quiere eliminar al cliente <b>{client.name}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteDialog} style={{ width: '450px' }} header="Confirmar" modal footer={deleteDialogFooter} onHide={hideDeleteDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {client && <span>Seguro que quiere eliminar el farmaco para este cliente?</span>}
                        </div>
                    </Dialog>
                </div>
            </div >
        </div >
    );
}
