import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { UserService } from '../service/UserService';
import { DialogProcesando } from '../components/DialogProcesando';

const userService = new UserService();

export const Users = () => {

    let emptySupplie = {
        id: null,
        name: '',
        email: '',
        password: '',
    };

    const [users, setUsers] = useState([]);
    const [userDialog, setUserDialog] = useState(false);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(emptySupplie);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        userService.getAll().then(data => {
            setUsers(data.data);
            setLoading(false);
        });
    }, []);

    const openNew = () => {
        setUser(emptySupplie);
        setSubmitted(false);
        setUserDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setUserDialog(false);
    }

    const saveProduct = () => {
        setSubmitted(true);

        if (user.name.trim() && user.email.trim() && user.password.trim()) {
            let _supplie = { ...user };
            let _supplies = [...users];
            setLoading(true);
            if (user.id) {
                const index = findIndexById(user.id);
                userService.update(_supplie).then(({ status, data }) => {
                    if (status === 'success') {
                        _supplies[index] = _supplie;
                        setUsers([..._supplies]);
                        setLoading(false);
                        toast.current.show({ severity: 'success', summary: 'Éxito', detail: 'Usuario actualizado exitosamente', life: 3000 });
                    }
                });
            }
            else {
                userService.create(user).then(({ status, data }) => {
                    if (status === 'success') {
                        setUsers([...users, data]);
                        setLoading(false);
                        toast.current.show({ severity: 'success', summary: 'Éxito', detail: 'Usuario creado exitosamente', life: 3000 });
                    }
                });
            }
            setUserDialog(false);
            setUser(emptySupplie);
        }
    }

    const editProduct = (product) => {
        setUser({ ...product });
        setUserDialog(true);
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < users.length; i++) {
            if (users[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _client = { ...user };
        _client[`${name}`] = val;

        setUser(_client);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nuevo" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} />
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Exportar" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => editProduct(rowData)} />
            </div>
        );
    }


    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Listado Usuarios</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const clientDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable ref={dt} value={users} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} usuarios" loading={loading}
                        globalFilter={globalFilter} emptyMessage="Sin usaurios registrados." header={header}>
                        <Column field="name" header="Nombre" sortable></Column>
                        <Column field="email" header="Email" sortable></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <DialogProcesando visible={loading} />


                    <Dialog visible={userDialog} style={{ width: '450px' }} header="Usuario Detalles" modal className="p-fluid" footer={clientDialogFooter} onHide={hideDialog}>
                        <div className="p-field">
                            <label htmlFor="name">Nombre</label>
                            <InputText id="name" value={user.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !user.name })} />
                            {submitted && !user.name && <small className="p-invalid" style={{ color: 'red' }}>El nombre es requerdio.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="email">Email</label>
                            <InputText id="email" value={user.email} onChange={(e) => onInputChange(e, 'email')} required autoFocus className={classNames({ 'p-invalid': submitted && !user.email })} />
                            {submitted && !user.email && <small className="p-invalid" style={{ color: 'red' }}>El Email es requerdio.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="password">Contraseña</label>
                            <InputText id="password" value={user.password} onChange={(e) => onInputChange(e, 'password')} required autoFocus className={classNames({ 'p-invalid': submitted && !user.password })} />
                            {submitted && !user.password && <small className="p-invalid" style={{ color: 'red' }}>La contraseña es requerdia.</small>}
                        </div>
                    </Dialog>

                </div>
            </div>
        </div>
    );
}
