import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { SupplieService } from '../service/SupplieService';
import { DialogProcesando } from '../components/DialogProcesando';
import { FileUpload } from 'primereact/fileupload';

const suplieService = new SupplieService();

export const Supplies = () => {

    let emptySupplie = {
        id: null,
        name: '',
    };

    const [supplies, setSupplies] = useState([]);
    const [supplieDialog, setSupplieDialog] = useState(false);
    const [loading, setLoading] = useState(true);
    const [supplie, setSupplie] = useState(emptySupplie);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [data, setData] = useState([]);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        suplieService.getAll().then(data => {
            setSupplies(data.data);
            setLoading(false);
        });
    }, []);

    const openNew = () => {
        setSupplie(emptySupplie);
        setSubmitted(false);
        setSupplieDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setSupplieDialog(false);
    }

    const saveProduct = () => {
        setSubmitted(true);

        if (supplie.name.trim()) {
            let _supplie = { ...supplie };
            let _supplies = [...supplies];
            setLoading(true);
            if (supplie.id) {
                const index = findIndexById(supplie.id);
                suplieService.update(_supplie).then(({ status, data }) => {
                    if (status === 'success') {
                        _supplies[index] = _supplie;
                        setSupplies([..._supplies]);
                        setLoading(false);
                        toast.current.show({ severity: 'success', summary: 'Éxito', detail: 'Medicamento actualizado exitosamente', life: 3000 });
                    }
                });
            }
            else {
                suplieService.create(supplie).then(({ status, data }) => {
                    if (status === 'success') {
                        setSupplies([...supplies, data]);
                        setLoading(false);
                        toast.current.show({ severity: 'success', summary: 'Éxito', detail: 'Medicamento creado exitosamente', life: 3000 });
                    }
                });
            }
            setSupplieDialog(false);
            setSupplie(emptySupplie);
        }
    }

    const editProduct = (product) => {
        setSupplie({ ...product });
        setSupplieDialog(true);
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < supplies.length; i++) {
            if (supplies[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _client = { ...supplie };
        _client[`${name}`] = val;

        setSupplie(_client);
    }

    const toCapitalize = (s) => {
        return s.charAt(0).toUpperCase() + s.slice(1);
    }

    const importExcel = (e) => {
        const file = e.files[0];

        import('xlsx').then(xlsx => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const wb = xlsx.read(e.target.result, { type: 'array' });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data = xlsx.utils.sheet_to_json(ws, { header: 1 });

                // Prepare DataTable
                const cols = data[0];
                data.shift();

                let _importedCols = cols.map(col => ({ field: col, header: toCapitalize(col) }));
                let _importedData = data.map(d => {
                    return cols.reduce((obj, c, i) => {
                        obj[c] = d[i];
                        return obj;
                    }, {});
                });
                setData(_importedData);
            };

            reader.readAsArrayBuffer(file);
        });

        const _data = data.map(value => {
            return {
                codigo: value.Código,
                codigo_barra: value['Código de Barra'],
                group: value.Grupo,
                name: value.Descripción
            }
        });

        suplieService.charger({ supplies: [..._data] }).then(data => console.log(data));
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nuevo" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} />
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Exportar" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
                {/* <FileUpload chooseOptions={{ label: 'Excel', icon: 'pi pi-file-excel', className: 'p-button-success' }} mode="basic" name="demo[]" auto url="https://primefaces.org/primereact/showcase/upload.php"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" className="p-mr-2" onUpload={importExcel} /> */}
            </React.Fragment>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => editProduct(rowData)} />
            </div>
        );
    }


    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Listado Medicamentos</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const clientDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable ref={dt} value={supplies} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando del {first} al {last} de {totalRecords} medicamentos" loading={loading}
                        globalFilter={globalFilter} emptyMessage="Sin medicamentos registrados." header={header}>
                        <Column field="codigo" header="Código" sortable></Column>
                        <Column field="codigo_barra" header="Código de Barra" sortable></Column>
                        <Column field="name" header="Nombre" sortable></Column>
                        <Column field="group.name" header="Grupo" sortable></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <DialogProcesando visible={loading} />


                    <Dialog visible={supplieDialog} style={{ width: '450px' }} header="Medicamento Detalles" modal className="p-fluid" footer={clientDialogFooter} onHide={hideDialog}>
                        <div className="p-field">
                            <label htmlFor="name">Nombre</label>
                            <InputText id="name" value={supplie.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !supplie.name })} />
                            {submitted && !supplie.name && <small className="p-invalid" style={{ color: 'red' }}>El nombre es requerdio.</small>}
                        </div>
                    </Dialog>

                </div>
            </div>
        </div>
    );
}
