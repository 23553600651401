import axios from 'axios';

export class SupplieService {

    async getAll() {
        return await axios
            .get(`${process.env.REACT_APP_API_URL}/api/supplies`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error.response;
            });
    }

    async create(data) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/supplie`, data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
                return error.response;
            });
    }

    async charger(data) {
        return await axios
            .post(`${process.env.REACT_APP_API_URL}/api/supplies/charger`, data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
                return error.response;
            });
    }

    async update(data) {
        return await axios
            .put(`${process.env.REACT_APP_API_URL}/api/supplie/${data.id}`, data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
                return error.response;
            });
    }
}
